import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AddData, GetData } from "../../api/GeneralFile";
import { toast } from "react-toastify";

const NationalDateSet = () => {
  const [user, setUser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [nationalExamDate, setNationalExamDate] = useState("");

  const [nationalResultDate, setNationalResultDate] = useState("");

  const [nationalExamTime, setNationalExamTime] = useState(
    user[0]?.nationalExamTime ? user[0]?.nationalExamTime.split(" to ")[0] : "",
  );

  const [nationalExamTime2, setNationalExamTime2] = useState(
    user[0]?.nationalExamTime ? user[0]?.nationalExamTime.split(" to ")[1] : "",
  );

  const [nationalCameraPermission, setNationalCameraPermission] = useState(
    user?.nationalCameraPermission ? user?.nationalCameraPermission : "",
  );
  const [nationalAudioPermission, setNationalAudioPermission] = useState(
    user?.nationalAudioPermission ? user?.nationalAudioPermission : "",
  );

  const searchData = () => {
    GetData(`/gfoExamSchoolLogin/get-all`)
      .then((response) => {
        console.log(response, "response");
        setUser(response.data.results.paginatedUsers);
        setNationalExamDate(
          response.data.results.paginatedUsers[0]?.nationalExamdate
            ? new Date(
                response.data.results.paginatedUsers[0]?.nationalExamdate,
              )
                ?.toISOString()
                .split("T")[0]
            : "",
        );
        setNationalResultDate(
          response.data.results.paginatedUsers[0]?.nationalResultdate
            ? new Date(
                response.data.results.paginatedUsers[0]?.nationalResultdate,
              )
                ?.toISOString()
                .split("T")[0]
            : "",
        );

        setNationalCameraPermission(
          response.data.results.paginatedUsers[0]?.nationalCameraPermission
            ? response.data.results.paginatedUsers[0]?.nationalCameraPermission
            : "",
        );
        setNationalAudioPermission(
          response.data.results.paginatedUsers[0]?.nationalAudioPermission
            ? response.data.results.paginatedUsers[0]?.nationalAudioPermission
            : "",
        );

        setNationalExamTime(
          response.data.results.paginatedUsers[0]?.nationalExamTime
            ? response.data.results.paginatedUsers[0]?.nationalExamTime.split(
                " to ",
              )[0]
            : "",
        );

        setNationalExamTime2(
          response.data.results.paginatedUsers[0]?.nationalExamTime
            ? response.data.results.paginatedUsers[0]?.nationalExamTime.split(
                " to ",
              )[1]
            : "",
        );
      })
      .catch((err) => {
        console.error(err, "error");
      });
  };

  useEffect(() => {
    searchData();
  }, []);

  const handleSubmit = () => {
    const nationalExamTimeGap = `${nationalExamTime} to ${nationalExamTime2}`;
    setIsLoading(true);
    const data = {
      nationalExamDate,
      nationalResultDate,
      nationalExamTime: nationalExamTimeGap,
      nationalCameraPermission: nationalCameraPermission,
      nationalAudioPermission: nationalAudioPermission,
    };
    AddData(`/gfoExamSchoolLogin/update-all`, data)
      .then((response) => {
        console.log(response, "response");
        toast.success("Dates updated successfully");
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err, "error");
        toast.error(err.response?.data?.message || "An error occurred");
        setIsLoading(false);
      });
  };

  return (
    <>
      <div
        style={{
          marginTop: "15px",
          display: "flex",
          justifyContent: "space-around",
          gap: "30px",
        }}
      >
        <h1>National Exam Date</h1>
        <h1>National Exam Time</h1>
        <h1>National Result Date</h1>
      </div>
      <div style={{ display: "flex", gap: "30px" }}>
        <TextField
          fullWidth
          type="date"
          // label="National Exam Date"

          inputProps={{
            min: !nationalExamDate && new Date().toISOString().split("T")[0],
          }}
          value={nationalExamDate}
          onChange={(event) =>
            setNationalExamDate(
              event.target.value
                ? new Date(event.target.value)?.toISOString().split("T")[0]
                : "",
            )
          }
        />
        <div>
          <TextField
            fullWidth
            type="time"
            value={nationalExamTime}
            onChange={(e) => setNationalExamTime(e.target.value)}
          />
        </div>
        <div>
          <TextField
            fullWidth
            type="time"
            value={nationalExamTime2}
            onChange={(e) => setNationalExamTime2(e.target.value)}
          />
        </div>

        <TextField
          fullWidth
          type="date"
          // label="National Result Date"

          inputProps={{
            min: !nationalResultDate && new Date().toISOString().split("T")[0],
          }}
          value={nationalResultDate}
          onChange={(event) =>
            setNationalResultDate(
              event.target.value
                ? new Date(event.target.value)?.toISOString().split("T")[0]
                : "",
            )
          }
        />
      </div>
      <div style={{ display: "flex", gap: "30px", marginTop: "15px" }}>
        <FormControl fullWidth>
          <InputLabel id="status-select-label">Camera Permission</InputLabel>
          <Select
            labelId="status-select-label"
            id="status-select"
            label="Camera Permission"
            required
            value={nationalCameraPermission}
            onChange={(event) =>
              setNationalCameraPermission(event.target.value)
            }
          >
            <MenuItem value="On">On</MenuItem>
            <MenuItem value="Off">Off</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="status-select-label">Audio Permission</InputLabel>
          <Select
            labelId="status-select-label"
            id="status-select"
            label="Audio Permission"
            required
            value={nationalAudioPermission}
            onChange={(event) => setNationalAudioPermission(event.target.value)}
          >
            <MenuItem value="On">On</MenuItem>
            <MenuItem value="Off">Off</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
        <Button
          type="submit"
          variant="contained"
          disabled={isLoading}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default NationalDateSet;
