import React, { useEffect, useRef, useState } from "react";
import { AddData, GetData } from "../../api/GeneralFile";
import { toast } from "react-toastify";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import loadingGIF from "../../assets/images/loading-gif.gif";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ReactDOM from "react-dom";
import GSTInvoiceFormate from "./GSTInvoiceFormate";
import DownloadIcon from "@mui/icons-material/Download";
import Swal from "sweetalert2";

const Finance = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsLimit, setRowsLimit] = useState(10);
  const [category, setCategory] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [totalData, setTotalData] = useState([]);
  const [allSubscriptionList, setAllSubscriptionList] = useState([]);
  const [grade, setGrade] = useState("");
  const [distinctGrades, setDistinctGrades] = useState([]);
  const [testingData, setTestingData] = useState(false);
  const [allData, setAllData] = useState([]);

  const { t } = useTranslation();

  const getAllSubscriptionList = () => {
    GetData(`/subscription-settings`)
      .then((response) => {
        console.log(response, "response");

        setAllSubscriptionList(
          response.data.results.paginatedSubscriptionSettings,
        );
      })
      .catch((err) => {
        console.error(err, "errpr");
        toast(err.data?.message);
      });
  };

  useEffect(() => {
    getAllSubscriptionList();
  }, []);

  const searchData = () => {
    setIsLoading(true);
    GetData(
      `/finance-details/subscriptions/details?grade=${grade}&category=${category}&pageNumber=${pageNumber}&rowsLimit=${rowsLimit}&startDate=${startDate}&endDate=${endDate}&testingData=${testingData}`,
    )
      .then((response) => {
        console.log(response, "response");
        setAllData(response.data.filteredBygrade);
        setTotalData(response.data.results.paginatedData);
        setTotalPage(response.data.results.totalPage);
        setDistinctGrades(response.data.distinctGrades);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err, "errpr");
        toast(err.data?.message);

        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    searchData();
  }, [pageNumber, rowsLimit, category, grade, testingData]);

  function convertTimestampToDate(timestamp) {
    const date = new Date(timestamp); // Convert timestamp to a Date object
    const day = String(date.getDate()).padStart(2, "0"); // Get day and add leading zero if necessary
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so add 1 and pad with zero
    const year = date.getFullYear(); // Get the year

    return `${day}-${month}-${year}`; // Return the formatted date
  }

  const contentRef = useRef();

  const handleDownloadPdf = () => {
    const content = contentRef.current;

    // Use html2canvas to capture the content as a canvas image
    html2canvas(content, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg");

      // Initialize jsPDF with A4 size (210mm x 297mm)
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      // Set PDF width and height
      const pdfWidth = 210; // A4 width in mm
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width; // Proportional height

      // Add the image to the PDF and adjust for A4 size
      pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);

      // Save the generated PDF
      pdf.save("download.pdf");
    });
  };

  // const handlePrintAll = async () => {
  //   const pdf = new jsPDF({
  //     orientation: "portrait",
  //     unit: "mm",
  //     format: "a4",
  //   });

  //   for (let i = 0; i < totalData.length; i++) {
  //     const element = document.createElement("div");
  //     document.body.appendChild(element);
  //     ReactDOM.render(
  //       <GSTInvoiceFormate invoiceData={totalData[i]} />,
  //       element,
  //     );

  //     const inputHeight = element.clientHeight;
  //     const inputWidth = element.clientWidth;

  //     const scale = 2; // Adjust this scale factor as needed

  //     const canvas = await html2canvas(element, {
  //       // scale: scale,
  //       // height: inputHeight,
  //       // width: inputWidth,
  //       // logging: true,
  //       // useCORS: true,
  //     });

  //     const imgData = canvas.toDataURL("image/jpeg", 0.8);

  //     pdf.addImage(imgData, "JPEG", 0, 0, 297, 210);
  //     if (i >= 0) {
  //       pdf.addPage();
  //     }

  //     document.body.removeChild(element);
  //   }

  //   pdf.save(`Download.pdf`);
  // };

  const [isDownloading, setIsDownloading] = useState(false);
  const handlePrintAll = async () => {
    setIsDownloading(true);
    const pdf = new jsPDF("p", "mm", "a4"); // Create a new PDF document
    let isFirstPage = true;
    const filterData = allData.filter((item) => !item?.user?.isTestingAccount);
    console.log(filterData);
    if (filterData.length > 0) {
      for (let i = 0; i < filterData.length; i++) {
        const element = document.createElement("div");
        document.body.appendChild(element);
        ReactDOM.render(
          <GSTInvoiceFormate invoiceData={filterData[i]} index={i + 1} />,
          element,
        );
        const canvas = await html2canvas(element, { scale: 2 });

        const imgData = canvas.toDataURL("image/jpeg");
        const imgWidth = 210; // width of A4 page in mm
        const pageHeight = 295; // height of A4 page in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        if (isFirstPage) {
          pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
          isFirstPage = false;
        } else {
          // For subsequent invoices, add a new page
          pdf.addPage();
          pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
        }
        document.body.removeChild(element);
      }

      pdf.save(
        `Subscription_Invoice_(${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear()}).pdf`,
      );
    }
    setIsDownloading(false);
  };

  const handlePrintSingle = async (data, index) => {
    const pdf = new jsPDF("p", "mm", "a4"); // Create a new PDF document

    const element = document.createElement("div");
    document.body.appendChild(element);
    ReactDOM.render(
      <GSTInvoiceFormate invoiceData={data} index={index + 1} />,
      element,
    );
    const canvas = await html2canvas(element, { scale: 2 });

    const imgData = canvas.toDataURL("image/jpeg");
    const imgWidth = 210; // width of A4 page in mm
    const pageHeight = 295; // height of A4 page in mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);

    document.body.removeChild(element);

    pdf.save(`${data?.user?.Name}_Invoice.pdf`);
  };

  const statusHandler = (status, id) => {
    AddData(`/user/set/testing/account`, {
      userid: id,
      status,
    })
      .then((response) => {
        toast(response.data?.message);
        searchData();
      })
      .catch((err) => {
        console.error(err, "errpr");
        toast(err.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div
        style={{
          padding: "15px",
          marginTop: "15px",
          backgroundColor: "#fff",
          justifyContent: "space-between",
          display: "flex",
        }}
      >
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">
            Category{" "}
          </FormLabel>
          <RadioGroup
            row
            aria-label="category"
            name="category"
            value={category}
            onChange={(event) => {
              setCategory(event.target.value);
            }}
          >
            {allSubscriptionList?.map(
              (item, i) =>
                item?.name !== "Free Access" && (
                  <FormControlLabel
                    key={String(i + 1)}
                    value={item?.name}
                    control={<Radio />}
                    label={`${item?.name} (₹ ${item?.price})`}
                  />
                ),
            )}
          </RadioGroup>
        </FormControl>
        <Button
          variant="contained"
          onClick={handlePrintAll}
          disabled={isDownloading}
        >
          {isDownloading ? "Downloading..." : "Download All Invoice as PDF"}
        </Button>
        <div style={{ flexDirection: "row", display: "flex", gap: "20px" }}>
          <div style={{ borderWidth: 2, padding: 5 }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Switch
                    checked={testingData}
                    onChange={(event) => {
                      const newStatus = event.target.checked;
                      setTestingData(newStatus);
                    }}
                    name="statusToggle"
                    color="primary"
                  />
                }
                label={testingData ? "Regular Account" : "All Data"}
              />
            </FormControl>
          </div>

          <FormControl>
            <TextField
              type="date"
              value={startDate}
              onChange={(event) => {
                setStartDate(event.target.value);
              }}
            />
          </FormControl>

          <FormControl>
            <TextField
              type="date"
              value={endDate}
              onChange={(event) => {
                setEndDate(event.target.value);
              }}
            />
          </FormControl>
          <Button
            variant="contained"
            onClick={searchData}
            style={{ margin: 10 }}
          >
            Filter
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setStartDate("");
              setEndDate("");
            }}
            style={{ marginRight: 10 }}
          >
            Cancel
          </Button>
        </div>
      </div>
      {/* <GSTInvoiceFormate /> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        {" "}
        <FormControl>
          <FormLabel>Class </FormLabel>
          <RadioGroup
            row
            aria-label="grade"
            name="grade"
            value={grade}
            onChange={(event) => {
              setGrade(event.target.value);
            }}
          >
            {distinctGrades?.length > 0 &&
              distinctGrades?.map((std, index) => (
                <FormControlLabel
                  value={std}
                  control={<Radio />}
                  label={std}
                  key={String(index + 1)}
                />
              ))}
          </RadioGroup>
        </FormControl>
      </div>
      <div>
        {isLoading ? (
          <img
            src={loadingGIF}
            alt=""
            style={{ width: "50px", height: "50px" }}
          />
        ) : (
          <>
            {" "}
            <div style={{ marginTop: "3px" }}>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="collapsible table">
                  <TableHead>
                    <TableRow sx={{ backgroundColor: "#333", color: "#fff" }}>
                      <TableCell sx={{ color: "#fff" }}>{t("Name")}</TableCell>
                      <TableCell sx={{ color: "#fff" }}>Phone No.</TableCell>
                      <TableCell sx={{ color: "#fff" }}>Grade</TableCell>
                      <TableCell sx={{ color: "#fff" }}>Plan Name</TableCell>
                      <TableCell sx={{ color: "#fff" }}>Payment Id</TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        Original Plan Price
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>CGST</TableCell>
                      <TableCell sx={{ color: "#fff" }}>SGST</TableCell>
                      <TableCell sx={{ color: "#fff" }}>Total Tax</TableCell>
                      <TableCell sx={{ color: "#fff" }}>Total Price</TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        Purchased Plan Date
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>Invoice</TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        Is Testing Account?
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {totalData?.map((item, index) => (
                      <TableRow key={String(index + 1)}>
                        <TableCell>{item.user?.Name}</TableCell>
                        <TableCell>{item.user?.Phone || "-"}</TableCell>
                        <TableCell>{item.user?.exam?.ename || "-"}</TableCell>
                        <TableCell>
                          {item.subscriptionHistory?.subscription?.name || "-"}
                        </TableCell>
                        <TableCell>
                          {item.subscriptionHistory?.paymentId || "-"}
                        </TableCell>
                        <TableCell>
                          {item.subscriptionHistory?.subscription?.price || "-"}
                        </TableCell>
                        <TableCell>9%</TableCell>
                        <TableCell>9%</TableCell>
                        <TableCell>
                          {`${(
                            (parseInt(
                              item.subscriptionHistory.subscription.price,
                            ) *
                              9) /
                            100
                          ).toFixed(2)}+${(
                            (parseInt(
                              item.subscriptionHistory.subscription.price,
                            ) *
                              9) /
                            100
                          ).toFixed(2)}`}
                        </TableCell>
                        <TableCell>
                          {parseInt(
                            item.subscriptionHistory.subscription?.price,
                          ) +
                            (parseInt(
                              item.subscriptionHistory.subscription.price,
                            ) *
                              18) /
                              100 || "-"}
                        </TableCell>
                        <TableCell>
                          {convertTimestampToDate(
                            item.subscriptionHistory.date,
                          ) || "-"}
                        </TableCell>
                        <TableCell>
                          {!item.user?.isTestingAccount && (
                            <Button
                              size="small"
                              variant="contained"
                              sx={{ marginRight: "5px" }}
                              onClick={() => handlePrintSingle(item, index)}
                            >
                              <DownloadIcon />
                            </Button>
                          )}
                        </TableCell>
                        <TableCell>
                          <FormControl fullWidth>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={item.user?.isTestingAccount}
                                  onChange={(event) => {
                                    const newStatus = event.target.checked;

                                    Swal.fire({
                                      title: newStatus
                                        ? "Are you sure you want to mark this as a Testing Account?"
                                        : "Are you sure you want to remove this from Testing Account status?",
                                      showDenyButton: true,
                                      confirmButtonText: "Yes",
                                      denyButtonText: `No`,
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        statusHandler(
                                          newStatus,
                                          item.user?._id,
                                        );
                                      }
                                    });
                                  }}
                                  name="statusToggle"
                                  color="primary"
                                />
                              }
                              label={
                                item.user?.isTestingAccount
                                  ? "Testing Account"
                                  : "Regular Account"
                              }
                            />
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div
              style={{
                marginTop: "15px",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <FormControl>
                <InputLabel id="demo-simple-select-label">Rows</InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={rowsLimit}
                  label="Rows limit"
                  onChange={(event) => {
                    setRowsLimit(event.target.value);
                    setPageNumber(1);
                  }}
                >
                  <MenuItem value="5">5</MenuItem>
                  <MenuItem value="10">10</MenuItem>
                  <MenuItem value="15">15</MenuItem>
                </Select>
              </FormControl>
              <div
                style={{
                  display: "flex",
                  gap: "0px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <SkipPreviousIcon
                  onClick={(event) =>
                    setPageNumber((pageNumber) =>
                      pageNumber <= 1 ? 1 : --pageNumber,
                    )
                  }
                />
                <div style={{ minWidth: "50px", textAlign: "center" }}>
                  {pageNumber} of {totalPage}
                </div>
                <SkipNextIcon
                  onClick={(event) =>
                    setPageNumber((pageNumber) =>
                      pageNumber < totalPage ? ++pageNumber : totalPage,
                    )
                  }
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Finance;
