import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const GSTInvoiceFormate = ({ invoiceData, text, index }) => {
  function convertTimestampToDate(timestamp) {
    const date = new Date(timestamp); // Convert timestamp to a Date object
    const day = String(date.getDate()).padStart(2, "0"); // Get day and add leading zero if necessary
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so add 1 and pad with zero
    const year = date.getFullYear(); // Get the year

    return `${day}-${month}-${year}`; // Return the formatted date
  }
  const totalData = [
    {
      id: 1,
      description: `GFO ${invoiceData?.subscriptionHistory?.subscription?.name} ${text && text === "ModalPaper" ? invoiceData?.subscriptionHistory?.level : ""}${text && text === "ModalPaper" ? `-${invoiceData?.subscriptionHistory?.grade}` : ""}`,
      unitprice: `₹ ${invoiceData?.subscriptionHistory?.subscription?.price}`,
      qty: 1,
      amount: `₹ ${invoiceData?.subscriptionHistory?.subscription?.price}`,
    },
    {
      id: 2,
      description: `${invoiceData?.subscriptionHistory?.subscription?.duration} ${text && text === "ModalPaper" ? "" : "days"} access to ${invoiceData?.subscriptionHistory?.subscription?.name} to practise on skitii website`,
      unitprice: "CGST @ 9%",
      qty: "",
      amount: `+ ₹ ${((invoiceData?.subscriptionHistory?.subscription?.price * 9) / 100).toFixed(2)}`,
    },
    {
      id: 3,
      description: "HSN - 999294",
      unitprice: "SGST @ 9%",
      qty: "",
      amount: `+ ₹ ${((invoiceData?.subscriptionHistory?.subscription?.price * 9) / 100).toFixed(2)}`,
    },
    // {
    //   id: 4,
    //   description: "",
    //   unitprice: "",
    //   qty: "",
    //   amount: "Tax Exclusive, Rounded-off",
    // },
  ];
  return (
    <div
      style={{
        paddingTop: 200,
      }}
    >
      <div
        style={{
          //   width: "210mm",
          //   height: "297mm",
          padding: "10mm",
        }}
      >
        <div>
          <p className="text-black font-bold text-[60px]">
            MINDFUL GURUKUL PRIVATE LIMITED
          </p>
          <p className="text-black  text-2xl mt-16">GSTIN - 27AAPCM7299D1ZU</p>
        </div>

        <div>
          <h1 className="text-black font-bold text-4xl mt-20">
            Invoice{"   "}
            <span className="text-gray-500 text-2xl">{` Receipt: ${index}`}</span>
          </h1>
          <h1 className="text-black font-normal text-3xl mt-5">
            GFO is a national Level Exam conducted for K12 Students
          </h1>
        </div>
        <div className="flex flex-row justify-between mt-20">
          <div>
            <h1 className="text-gray-500 text-3xl font-bold">ISSUE DATE</h1>
            <h1 className="text-black font-bold text-3xl mt-5">
              {convertTimestampToDate(invoiceData?.subscriptionHistory?.date)}
            </h1>
          </div>
          <div>
            <h1 className="text-gray-500 text-3xl font-bold">
              PLACE OF SUPPLY
            </h1>
            <h1 className="text-black font-bold text-3xl mt-5">Online</h1>
          </div>
          <div></div>
        </div>

        <div className="mt-52">
          <TableContainer
            // component={Paper}
            sx={{ border: "none" }}
          >
            <Table size="small" aria-label="collapsible table">
              <TableHead style={{ justifyContent: "center" }}>
                <TableRow
                  sx={{
                    backgroundColor: "#cccccc",
                    color: "#fff",
                  }}
                >
                  <TableCell
                    sx={{
                      color: "#333",
                      fontWeight: "bold",
                      fontSize: 25,
                      padding: "20px",
                      marginBottom: "10px",
                    }}
                  >
                    DESCRIPTION
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#333",
                      fontWeight: "bold",
                      fontSize: 25,
                      padding: "20px",
                    }}
                  >
                    UNIT PRICE
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#333",
                      fontWeight: "bold",
                      fontSize: 25,
                      padding: "20px",
                    }}
                  >
                    QTY
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#333",
                      fontWeight: "bold",
                      fontSize: 25,
                      padding: "20px",
                    }}
                  >
                    AMOUNT
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {totalData?.map((item) => (
                  <TableRow
                    key={item.id}
                    sx={{
                      border: "none", // Remove row borders
                      "&:last-child td, &:last-child th": { border: 0 }, // Remove bottom border
                    }}
                  >
                    <TableCell
                      sx={{
                        fontSize: 25,
                        borderBottom: "none",
                        paddingTop: item.id === 1 ? "40px" : "2px",
                        fontWeight: item.id === 1 ? "bold" : "normal",
                      }}
                    >
                      {item?.description}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: 25,
                        borderBottom: "none",
                        paddingTop: item.id === 1 ? "40px" : "2px",
                      }}
                    >
                      {item?.unitprice}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: 25,
                        borderBottom: "none",
                        paddingTop: item.id === 1 ? "40px" : "2px",
                      }}
                    >
                      {item?.qty}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: 25,
                        borderBottom: "none",
                        paddingTop: item.id === 1 ? "40px" : "2px",
                      }}
                    >
                      {item?.amount}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow
                  sx={{
                    border: "none", // Remove row borders
                    "&:last-child td, &:last-child th": { border: 0 }, // Remove bottom border
                  }}
                >
                  <TableCell
                    sx={{
                      fontSize: 25,
                      borderBottom: "none",
                      paddingTop: "2px",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      fontSize: 25,
                      borderBottom: "none",
                      paddingTop: "2px",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      fontSize: 25,
                      borderBottom: "none",
                      paddingTop: "2px",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      fontSize: 15,
                      borderBottom: "none",
                      paddingTop: "20px",
                    }}
                  >
                    Tax Exclusive,<br></br> Rounded-off
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    border: "none", // Remove row borders
                    "&:last-child td, &:last-child th": { border: 0 }, // Remove bottom border
                  }}
                >
                  <TableCell
                    sx={{
                      fontSize: 25,
                      borderBottom: "none",
                      paddingTop: "2px",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      fontSize: 25,
                      borderBottom: "none",
                      paddingTop: "15px",
                    }}
                  >
                    Sub Total
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: 25,
                      borderBottom: "none",
                      paddingTop: "2px",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      fontSize: 25,
                      borderBottom: "none",
                      paddingTop: "15px",
                    }}
                  >
                    {`₹ ${invoiceData?.subscriptionHistory?.subscription?.price}`}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    border: "none", // Remove row borders
                    "&:last-child td, &:last-child th": { border: 0 }, // Remove bottom border
                  }}
                >
                  <TableCell
                    sx={{
                      fontSize: 25,
                      borderBottom: "none",
                      paddingTop: "2px",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      fontSize: 25,
                      borderBottom: "none",
                      paddingTop: "15px",
                    }}
                  >
                    Total Tax
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: 25,
                      borderBottom: "none",
                      paddingTop: "2px",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      fontSize: 25,
                      borderBottom: "none",
                      paddingTop: "15px",
                    }}
                  >
                    {/* ₹ 36.00 */}
                    {`₹ ${(parseFloat(((invoiceData?.subscriptionHistory?.subscription?.price * 9) / 100).toFixed(2)) + parseFloat(((invoiceData?.subscriptionHistory?.subscription?.price * 9) / 100).toFixed(2))).toFixed(2)}`}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    border: "none", // Remove row borders
                    "&:last-child td, &:last-child th": { border: 0 }, // Remove bottom border
                  }}
                >
                  <TableCell
                    sx={{
                      fontSize: 25,
                      borderBottom: "none",
                      paddingTop: "2px",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      fontSize: 25,
                      borderBottom: "none",
                      paddingTop: "15px",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: 25,
                      borderBottom: "none",
                      paddingTop: "2px",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      fontSize: 25,
                      borderBottom: "none",
                      paddingTop: "15px",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {`₹ ${(parseFloat(invoiceData?.subscriptionHistory?.subscription?.price) + parseFloat(((invoiceData?.subscriptionHistory?.subscription?.price * 9) / 100).toFixed(2)) + parseFloat(((invoiceData?.subscriptionHistory?.subscription?.price * 9) / 100).toFixed(2))).toFixed(2)}`}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {/* <div className="flex justify-center items-center">
          <h1 className="text-center mt-[150mm] ">
            417 chandralok B Bldg 12, Manav Mandir Road walkeshwar Mumbai-6
            email id:customercare@mindfulgurukul.com Mob:9820934635
          </h1>
        </div> */}
        <div className="flex flex-row gap-[190mm] justify-center items-center mt-96 ">
          <div>
            <h1 className="text-gray-500 text-4xl font-bold text-center">
              Address
            </h1>
            <h1 className="text-black text-2xl font-bold text-center mt-8">
              417 chandralok B Bldg 12,<br></br> Manav Mandir Road walkeshwar
              <br></br> Mumbai-6
            </h1>
          </div>
          <div>
            <h1 className="text-gray-500 text-4xl font-bold  text-center">
              Email & Mobile No.
            </h1>
            <h1 className="text-black text-2xl font-bold text-center mt-6">
              customercare@mindfulgurukul.com{" "}
            </h1>
            <h1 className="text-black text-2xl font-bold text-center mt-3">
              9820934635
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GSTInvoiceFormate;
