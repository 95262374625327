import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useSelector } from "react-redux";
import LoadingErrorMessageComponent from "./LoadingErrorMessageComponent";
import { Box, Tab, Tabs } from "@mui/material";
import Finance from "../components/financeData/Finance";
import GfoPlanFinance from "../components/financeData/GfoPlanFinance";
import ModalPaperPdfPlan from "../components/financeData/ModalPaperPdfPlan";

const FinanceDetails = () => {
  const cookies = new Cookies();
  const token = cookies.get("TOKEN");
  const [isAllowed, setIsAllowed] = useState(false);
  const [isAllowedResponse, setIsAllowedResponse] = useState("");
  const [isCheckingRights, setIsCheckingRights] = useState(true);
  const [tabValue, setTabValue] = useState(0);

  const role = useSelector((state) => state.role);
  useEffect(() => {
    if (token) {
      const configuration = {
        method: "POST",
        url: "/role-management/get",
        data: { role },
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      setIsCheckingRights(true);
      axios(configuration)
        .then((response) => {
          console.log(response);

          if (response.data?.result?.allowFinanceDetails === true) {
            setIsAllowed(true);
          } else {
            setIsAllowedResponse(
              "You dont't have permission to access this page.",
            );
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsCheckingRights(false);
        });
    }
  }, [token, role]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <LoadingErrorMessageComponent
        isCheckingRights={isCheckingRights}
        isAllowed={isAllowed}
        isAllowedResponse={isAllowedResponse}
      />
      {isAllowed && (
        <>
          <Box
            sx={{
              maxWidth: { xs: 320, sm: "100%" },
              bgcolor: "background.paper",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs"
            >
              <Tab label="Subscription" />
              <Tab label="State / National" />
              <Tab label="Model Paper" />
            </Tabs>
          </Box>

          {tabValue === 0 ? <Finance /> : ""}
          {tabValue === 1 ? <GfoPlanFinance /> : ""}
          {tabValue === 2 ? <ModalPaperPdfPlan /> : ""}
        </>
      )}
    </div>
  );
};

export default FinanceDetails;
